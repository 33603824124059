import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'semantic-ui-react';
import formatter from '../../../utils/formatter';
import _ from 'lodash';

const HOURS = [
  {
    text: '12 a.m',
    value: '00',
  },
  {
    text: '1 a.m',
    value: '01',
  },
  {
    text: '2 a.m',
    value: '02',
  },
  {
    text: '3 a.m',
    value: '03',
  },
  {
    text: '4 a.m',
    value: '04',
  },
  {
    text: '5 a.m',
    value: '05',
  },
  {
    text: '6 a.m',
    value: '06',
  },
  {
    text: '7 a.m',
    value: '07',
  },
  {
    text: '8 a.m',
    value: '08',
  },
  {
    text: '9 a.m',
    value: '09',
  },
  {
    text: '10 a.m',
    value: '10',
  },
  {
    text: '11 a.m',
    value: '11',
  },
  {
    text: '12 p.m',
    value: '12',
  },
  {
    text: '1 p.m',
    value: '13',
  },
  {
    text: '2 p.m',
    value: '14',
  },
  {
    text: '3 p.m',
    value: '15',
  },
  {
    text: '4 p.m',
    value: '16',
  },
  {
    text: '5 p.m',
    value: '17',
  },
  {
    text: '6 p.m',
    value: '18',
  },
  {
    text: '7 p.m',
    value: '19',
  },
  {
    text: '8 p.m',
    value: '20',
  },
  {
    text: '9 p.m',
    value: '21',
  },
  {
    text: '10 p.m',
    value: '22',
  },
  {
    text: '11 p.m',
    value: '23',
  },
];

const MINUTES_SECONDS = [
  {
    text: '00',
    value: '00',
  },
  {
    text: '01',
    value: '01',
  },
  {
    text: '02',
    value: '02',
  },
  {
    text: '03',
    value: '03',
  },
  {
    text: '04',
    value: '04',
  },
  {
    text: '05',
    value: '05',
  },
  {
    text: '06',
    value: '06',
  },
  {
    text: '07',
    value: '07',
  },
  {
    text: '08',
    value: '08',
  },
  {
    text: '09',
    value: '09',
  },
  {
    text: '10',
    value: '10',
  },
  {
    text: '11',
    value: '11',
  },
  {
    text: '12',
    value: '12',
  },
  {
    text: '13',
    value: '13',
  },
  {
    text: '14',
    value: '14',
  },
  {
    text: '15',
    value: '15',
  },
  {
    text: '16',
    value: '16',
  },
  {
    text: '17',
    value: '17',
  },
  {
    text: '18',
    value: '18',
  },
  {
    text: '19',
    value: '19',
  },
  {
    text: '20',
    value: '20',
  },
  {
    text: '21',
    value: '21',
  },
  {
    text: '22',
    value: '22',
  },
  {
    text: '23',
    value: '23',
  },
  {
    text: '24',
    value: '24',
  },
  {
    text: '25',
    value: '25',
  },
  {
    text: '26',
    value: '26',
  },
  {
    text: '27',
    value: '27',
  },
  {
    text: '28',
    value: '28',
  },
  {
    text: '29',
    value: '29',
  },
  {
    text: '30',
    value: '30',
  },
  {
    text: '31',
    value: '31',
  },
  {
    text: '32',
    value: '32',
  },
  {
    text: '33',
    value: '33',
  },
  {
    text: '34',
    value: '34',
  },
  {
    text: '35',
    value: '35',
  },
  {
    text: '36',
    value: '36',
  },
  {
    text: '37',
    value: '37',
  },
  {
    text: '38',
    value: '38',
  },
  {
    text: '39',
    value: '39',
  },
  {
    text: '40',
    value: '40',
  },
  {
    text: '41',
    value: '41',
  },
  {
    text: '42',
    value: '42',
  },
  {
    text: '43',
    value: '43',
  },
  {
    text: '44',
    value: '44',
  },
  {
    text: '45',
    value: '45',
  },
  {
    text: '46',
    value: '46',
  },
  {
    text: '47',
    value: '47',
  },
  {
    text: '48',
    value: '48',
  },
  {
    text: '49',
    value: '49',
  },
  {
    text: '50',
    value: '50',
  },
  {
    text: '51',
    value: '51',
  },
  {
    text: '52',
    value: '52',
  },
  {
    text: '53',
    value: '53',
  },
  {
    text: '54',
    value: '54',
  },
  {
    text: '55',
    value: '55',
  },
  {
    text: '56',
    value: '56',
  },
  {
    text: '57',
    value: '57',
  },
  {
    text: '58',
    value: '58',
  },
  {
    text: '59',
    value: '59',
  },
];


class HourInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hours: '00',
      minutes: '00',
      seconds: '00',
      dirtyHours: false,
      dirtyMinutes: false,
      dirtySeconds: false,
      valid: true,
      validHours: true,
      validMinutes: true,
      validSeconds: true,
    };
  }


  // -----------------------------
  // ------ life cycle events ----
  // -----------------------------
  componentDidMount() {
    this.setForm();

    if (this.props.defaultValue) {
      this.loadValue(this.props.defaultValue);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { dirtyHours, dirtyMinutes, dirtySeconds } = this.state;
    const inputDirty = dirtyHours || dirtyMinutes || dirtySeconds;

    if (!inputDirty && nextProps.defaultValue) {
      this.loadValue(nextProps.defaultValue);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !_.isEqual(nextState, this.state) || !_.isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.eq(prevState, this.state)) {
      this.setForm();
    }
  }


  // -----------------------
  // ------ user events ----
  // -----------------------
  onChangeSeconds(e, data) {
    this.setState({ seconds: data.value, dirtySeconds: true });
  }

  onChangeMinutes(e, data) {
    this.setState({ minutes: data.value, dirtyMinutes: true });
  }

  onChangeHours(e, data) {
    this.setState({ hours: data.value, dirtyHours: true });
  }

  setForm() {
    this.props.setFormData(this.props.name, this.state);
  }

  getValue() {
    const { hours, minutes, seconds } = this.state;
    return `${hours}:${minutes}:${seconds}`;
  }

  loadValue(value) {
    const rawValues = value.split(':');
    const hours = rawValues[0];
    const minutes = rawValues[1];
    const seconds = rawValues[2];

    if (hours) {
      this.setState({ hours });
    }

    if (minutes) {
      this.setState({ minutes });
    }

    if (seconds) {
      this.setState({ seconds });
    }
  }

  dirtInput() {
    this.setState({
      dirtyHours: true,
      dirtyMinutes: true,
      dirtySeconds: true,
    });
  }

  resetInput() {
    this.setState({
      hours: '00',
      minutes: '00',
      seconds: '00',
      dirtyHours: false,
      dirtyMinutes: false,
      dirtySeconds: false,
      validHours: true,
      validMinutes: true,
      validSeconds: true,
    });

    if (this.props.defaultValue) {
      this.loadValue(this.props.defaultValue);
    }
  }


  // --------------------------
  // ------ render methods ----
  // --------------------------
  renderSeconds() {
    const invalidInput = this.state.dirtySeconds && !this.state.validSeconds;
    let className = '';
    let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
    let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

    if (this.props.className) {
      className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
    } else {
      className = invalidInput ? 'invalid' : 'valid';
    }

    if (invalidInput) {
      finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
      finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
    }

    return (
      <Form.Field>
        <label style={finalLabelStyle}>
          Segundos
        </label>

        <Dropdown
          fluid
          search
          selection
          name={`${this.props.name}-seconds`}
          className={className}
          value={this.state.seconds}
          onChange={this.onChangeSeconds.bind(this)}
          disabled={this.props.readOnly}
          placeholder="--"
          options={MINUTES_SECONDS}
          style={finalInputStyle}
          noResultsMessage="--"
        />
      </Form.Field>
    );
  }

  renderMinutes() {
    const invalidInput = this.state.dirtyMinutes && !this.state.validMinutes;
    let className = '';
    let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
    let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

    if (this.props.className) {
      className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
    } else {
      className = invalidInput ? 'invalid' : 'valid';
    }

    if (invalidInput) {
      finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
      finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
    }

    return (
      <Form.Field>
        <label style={finalLabelStyle}>
          Minutos
        </label>

        <Dropdown
          fluid
          search
          selection
          name={`${this.props.name}-minutes`}
          className={className}
          value={this.state.minutes}
          onChange={this.onChangeMinutes.bind(this)}
          disabled={this.props.readOnly}
          placeholder="--"
          options={MINUTES_SECONDS}
          style={finalInputStyle}
          noResultsMessage="--"
        />
      </Form.Field>
    );
  }

  renderHours() {
    const invalidInput = this.state.dirtyHours && !this.state.validHours;
    let className = '';
    let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
    let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

    if (this.props.className) {
      className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
    } else {
      className = invalidInput ? 'invalid' : 'valid';
    }

    if (invalidInput) {
      finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
      finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
    }

    return (
      <Form.Field>
        <label style={finalLabelStyle}>
          Hora
        </label>

        <Dropdown
          fluid
          search
          selection
          name={`${this.props.name}-hours`}
          className={className}
          value={this.state.hours}
          onChange={this.onChangeHours.bind(this)}
          disabled={this.props.readOnly}
          placeholder="--"
          options={HOURS}
          style={finalInputStyle}
          noResultsMessage="--"
        />
      </Form.Field>
    );
  }

  render() {
    return (
      <Form.Group widths="equal" style={{ marginBottom: 0 }}>
        { this.renderHours() }
        { this.renderMinutes() }
        { this.renderSeconds() }
      </Form.Group>
    );
  }
}


// estilos
const styles = {
  label: {

  },
  errorLabel: {
    color: '#9F3A38',
  },
  input: {

  },
  errorInput: {
    background: 'rgba(224, 180, 180, 0.48)',
    border: '1px solid #9F3A38',
    color: '#9F3A38',
  },
  popUpContainer: {
    position: 'absolute',
    top: 75,
    left: 0,
    zIndex: 100,
  },
  popUpErrorLabel: {
    fontSize: 13,
    textAlign: 'center',
    boxShadow: 'rgba(100, 100, 100, 0.25) 0px 2px 4px',
  },
  containerInput: {
    paddingLeft: 0,
    paddingRight: 0,
    width: 52,
  },
};


HourInput.propTypes = {
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  inputStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  setFormData: PropTypes.func.isRequired,
};


// exportar componente
export default HourInput;
