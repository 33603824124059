import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Grid, Header, Image } from 'semantic-ui-react';
import logo from '../../../../assets/OlaTechnologiesLogo.png';
import { MOBILE_BREAKPOINT } from '../../../../utils/constants';

const { Column } = Grid;


class LandingPageView extends Component {
  isMobile() {
    return window.innerWidth < MOBILE_BREAKPOINT;
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Ola Technologies</title>
          <meta name="description" content="Provides consulting services and digital solutions for the produce and meat industries" />
          <link rel="canonical" href="https://yomenu.mx/" />
        </Helmet>

        <div style={{ paddingLeft: 28, marginBottom: 40 }}>
          <Grid stackable container style={{ background: 'white', marginTop: 30, borderRadius: 7 }}>
            <Column style={{ textAlign: 'center' }}>
              <Image centered src={logo} size="small" style={{ marginTop: 20 }} />

              <Header style={{ marginBottom: 20, color: 'rgb(100,100,100)' }}>
                Providing consulting services and digital solutions for the meat and produce industries
              </Header>
            </Column>
          </Grid>
        </div>
      </div>
    );
  }
}


export default LandingPageView;
