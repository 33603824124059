import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'semantic-ui-react';
import formatter from '../../../utils/formatter';
import _ from 'lodash';


// crear componente
class RadioInput extends Component {
  constructor(props) {
    super(props);
    let valid;

    if (props.defaultValue) {
      valid = true;
    } else {
      valid = !props.required;
    }

    this.state = {
      value: props.defaultValue ? props.defaultValue : '',
      valid,
      message: props.required ? `El campo ${props.label} es requerido` : '',
      dirty: false,
    };
  }


  // -----------------------------
  // ------ life cycle events ----
  // -----------------------------
  componentDidMount() {
    this.setForm();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.dirty && nextProps.defaultValue) {
      this.setState({ value: nextProps.defaultValue, valid: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !_.isEqual(nextState, this.state) || !_.isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.eq(prevState, this.state)) {
      this.setForm();
    }
  }


  // -----------------------
  // ------ user events ----
  // -----------------------
  onChange(e, data) {
    this.setState({ value: data.value, dirty: true, valid: true });
  }

  setForm() {
    this.props.setFormData(this.props.name, this.state);
  }

  getValue() {
    return this.state.value;
  }

  dirtInput() {
    this.setState({ dirty: true });
  }

  resetInput() {
    let valid;

    if (this.props.defaultValue) {
      valid = true;
    } else {
      valid = !this.props.required;
    }

    this.setState({
      value: this.props.defaultValue ? this.props.defaultValue : '',
      valid,
      message: this.props.required ? `El campo ${this.props.label} es requerido` : '',
      dirty: false,
    });
  }


  // --------------------------
  // ------ render methods ----
  // --------------------------
  renderRadios() {
    return this.props.options.map((radio, index) => (
      <Radio
        key={index}
        name={this.props.name}
        label={radio.text}
        value={`${radio.value}`}
        checked={this.state.value === radio.value}
        onChange={this.onChange.bind(this)}
        className={this.props.className}
        style={{ marginRight: 10, ...this.props.inputStyle }}
      />
    ));
  }

  render() {
    if (this.props.inlineLabel) {
      return (
        <Form.Field>
          <span style={{ marginRight: 10, ...this.props.labelStyle }}>
            { this.props.label }
          </span>

          { this.renderRadios() }
        </Form.Field>
      );
    }

    return (
      <Form.Field>
        <label style={this.props.labelStyle}>
          { this.props.label }
        </label>

        { this.renderRadios() }
      </Form.Field>
    );
  }
}


RadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.object,
  inlineLabel: PropTypes.bool,
  inputStyle: PropTypes.object,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultValue: PropTypes.string,
  setFormData: PropTypes.func.isRequired,
  required: PropTypes.bool,
};


// exportar componente
export default RadioInput;
