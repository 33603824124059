import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { Route, Switch } from "react-router-dom";
import { isEqual } from 'lodash';
import LayoutHeader from './Header';
import formatter from '../../../utils/formatter';
import eventManager from '../../../utils/eventManager';
import { FadeInOutView } from '../../../components';
import { WHITE } from '../../../colors';
import { MOBILE_BREAKPOINT } from '../../../utils/constants';
import LandingPage from '../views/LandingPage';

const { Row, Column } = Grid;

const styles = {
  mainFooterContainer: {
    background: WHITE,
    boxShadow: '0 -3px 3px rgb(0,0,0,0.2)',
    zIndex: 20,
    margin: 0,
    width: '100%'
  },
  mainContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    margin: 0,
    overflowY: 'scroll'
  },
  link: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'avenir, Open-Sans, Lato, Arial',
  }
};


class WebLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      footerVisible: true,
      mainFooterHeight: 0,
      secundaryFooterHeight: 0,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    }
  }

  componentDidMount() {
    this.initNavigationListeners();

    window.onresize = () => {
      this.setWindowDimensions();
      this.setFooterHeight();
    };

    this.setWindowDimensions();
    this.setFooterHeight();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  componentWillUnmount() {
    eventManager.unsubscribe('start-navigation-middle', this.middleNavigationCallbackID);
    eventManager.unsubscribe('start-navigation-finished', this.finishedNavigationCallbackID);
    eventManager.unsubscribe('navigate', this.navigateCallbackID);
  }


  // ---------------------
  // ----- methods -------
  // ---------------------
  setFooterHeight() {
    const mainFooter = document.getElementById('main-footer');
    const secundaryFooter = document.getElementById('secundary-footer');

    if (mainFooter) {
      this.setState({ mainFooterHeight: mainFooter.offsetHeight });
    }

    if (secundaryFooter) {
      this.setState({ secundaryFooterHeight: secundaryFooter.offsetHeight });
    }
  }

  setWindowDimensions() {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  }

  getMinHeight() {
    const { windowHeight, mainFooterHeight, secundaryFooterHeight } = this.state;
    return windowHeight - mainFooterHeight - secundaryFooterHeight;
  }

  navigate(url) {
    let eventType = 'start-navigation';

    if (window.innerWidth < MOBILE_BREAKPOINT) {
      eventType = 'start-navigation-mobile';
    }

    eventManager.emit(eventType, () => {
      this.props.history.push(url);
    });
  }

  initNavigationListeners() {
    this.navigateCallbackID = eventManager.on('navigate', (url) => {
      this.navigate(url);
    });

    this.middleNavigationCallbackID = eventManager.on('start-navigation-middle', () => {
      if (window.innerWidth < MOBILE_BREAKPOINT) {
        this.setState({ footerVisible: false });
      }
    });

    this.finishedNavigationCallbackID = eventManager.on('start-navigation-finished', () => {
      this.setState({ footerVisible: true });
    });
  }


  // ----------------------------
  // ----- render methods -------
  // ----------------------------
  renderMainFooter() {
    const { footerVisible } = this.state;

    if (!footerVisible) {
      return null;
    }

    return (
      <Row id="main-footer" style={styles.mainFooterContainer}>
        <Column>
          <Grid container stackable columns={2} style={{ paddingLeft: 28 }}>
            <Column textAlign="center" style={{ color: 'rgb(100,100,100)' }}>
              <strong>2020 © Ola Technologies.</strong>
            </Column>

            <Column textAlign="center" style={{ color: 'rgb(100,100,100)' }}>
              <strong>Contact: </strong>

              <a href="mailto:info@olatechinc.com" style={{ marginLeft: 5, color: 'rgb(100,100,100)' }}>
                <strong>info@olatechinc.com</strong>
              </a>
            </Column>
          </Grid>
        </Column>
      </Row>
    );
  }

  renderViews() {
    const { windowWidth } = this.state;

    const views = (
      <Switch>
        <Route path="/" component={LandingPage} />
      </Switch>
    );

    if (windowWidth < MOBILE_BREAKPOINT) {
      return (
        <Row only="mobile" style={{ minHeight: this.getMinHeight(), paddingBottom: 0, marginBottom: -5 }}>
          <Column>
            <FadeInOutView eventType="start-navigation-mobile">
              <Grid style={{ marginTop: 70, marginBottom: 20 }}>
                <Column style={{ padding: 0 }}>
                  { views }
                </Column>
              </Grid>
            </FadeInOutView>
          </Column>
        </Row>
      );
    }

    return (
      <Row only="tablet computer" style={{ minHeight: this.getMinHeight(), paddingBottom: 0 }}>
        <Column>
          <FadeInOutView eventType="start-navigation">
            <Grid>
              <Column style={{ borderRadius: 5, marginTop: 70, marginBottom: 20, padding: 0 }}>
                { views }
              </Column>
            </Grid>
          </FadeInOutView>
        </Column>
      </Row>
    );
  }

  render() {
    return (
      <div style={{ overflowX: 'hidden' }}>
        <LayoutHeader navigate={this.navigate.bind(this)} />

        <Grid style={{ background: 'rgb(245, 245, 245)', margin: 0 }}>
          { this.renderViews() }
          { this.renderMainFooter() }
        </Grid>
      </div>
    );
  }
}


WebLayout.propTypes = {
  history: PropTypes.object,
}


export default WebLayout;
