import { call, put } from 'redux-saga/effects';
import notifications from '../../../utils/notifications';
import eventManager from '../../../utils/eventManager';
import { SET_LOADING } from '../store/types';
import * as calls from './calls';


export function* resetPassword(action) {
  try {
    yield put({ type: SET_LOADING, payload: { prop: 'reset_password', value: true } });
    const { application } = action.payload;
    let request;

    if (application === '1') {
      request = calls.resetDriverPassword(action.payload);
    } else if (application === '2') {
      request = calls.resetUserPassword(action.payload);
    }

    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      eventManager.emit('reset_password_success');
    } else {
      notifications.error(serverResponse.message || 'Ocurrio un error al reiniciar contraseña');
    }

    yield put({ type: SET_LOADING, payload: { prop: 'reset_password', value: false } });
  } catch (error) {
    notifications.error(typeof error === 'string' ? error : error.message);
    yield put({ type: SET_LOADING, payload: { prop: 'reset_password', value: false } });
  }
}

export function* validateEmail(action) {
  try {
    yield put({ type: SET_LOADING, payload: { prop: 'email_validation', value: true } });
    const { application } = action.payload;
    let request;

    if (application === '1') {
      request = calls.validateDriverEmail(action.payload);
    } else if (application === '2') {
      request = calls.validateUserEmail(action.payload);
    }

    const response = yield call(request);
    const serverResponse = response.data;

    eventManager.emit('email_validation_response', serverResponse);
    yield put({ type: SET_LOADING, payload: { prop: 'email_validation', value: false } });
  } catch (error) {
    notifications.error(typeof error === 'string' ? error : error.message);
    yield put({ type: SET_LOADING, payload: { prop: 'email_validation', value: false } });
  }
}
