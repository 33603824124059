import React, { Component } from 'react';
import PropTypes from 'prop-types';
import transitions from '../utils/transitions';
import eventManager from '../utils/eventManager';

const INITIAL_VALUE = 0;
const FINAL_VALUE = 1;


class FadeInOutView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentVisible: true,
      callback: null,
      opacity: INITIAL_VALUE,
    };
  }

  // --------------------------------
  // ------ life cycle events -------
  // --------------------------------
  componentDidMount() {
    this.fadeInAnimation();

    this.callbackID = eventManager.on(this.props.eventType, (callback) => {
      this.setState({ callback });
      this.fadeInOutAnimation();
    });
  }

  componentWillUnmount() {
    this.unmounted = true;
    eventManager.unsubscribe(this.props.eventType, this.callbackID);
  }


  // ----------------------------
  // --------- methods ----------
  // ----------------------------
  fadeInAnimation() {
    const params = {
      initialValue: INITIAL_VALUE,
      finalValue: FINAL_VALUE,
      duration: this.props.duration || 500,
    };

    transitions.easePolyOut(params, (response) => {
      if (!this.unmounted) {
        this.setState({ opacity: response.value });
      }
    });
  }

  fadeInOutAnimation() {
    const initialParams = {
      initialValue: FINAL_VALUE,
      finalValue: INITIAL_VALUE,
      duration: Math.round(this.props.duration || 500 / 2),
    };

    const finalParams = {
      initialValue: INITIAL_VALUE,
      finalValue: FINAL_VALUE,
      duration: Math.round(this.props.duration || 500 / 2),
    };

    transitions.easePolyOut(initialParams, (firstResponse) => {
      if (!this.unmounted) {
        this.setState({ opacity: firstResponse.value });
      }

      if (firstResponse.finished) {
        eventManager.emit(`${this.props.eventType}-middle`);

        this.setState({ contentVisible: false }, () => {
          setTimeout(() => {
            window.scrollTo(0, 0);
            this.state.callback();
            this.setState({ contentVisible: true });

            transitions.easePolyOut(finalParams, (secondResponse) => {
              if (!this.unmounted) {
                this.setState({ opacity: secondResponse.value });

                if (secondResponse.finished) {
                  eventManager.emit(`${this.props.eventType}-finished`);
                }
              }
            });
          }, 50);
        });
      }
    });
  }


  // -----------------------------
  // ------ render methods -------
  // -----------------------------
  renderContent() {
    const { contentVisible } = this.state;

    if (contentVisible) {
      return this.props.children;
    }

    return null;
  }

  render() {
    const { opacity } = this.state;

    return (
      <div style={{ opacity, width: '100%' }}>
        { this.renderContent() }
      </div>
    );
  }
}


FadeInOutView.propTypes = {
  duration: PropTypes.number,
  eventType: PropTypes.string.isRequired,
  children: PropTypes.any,
};


export { FadeInOutView };
