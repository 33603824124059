import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Input, Label } from 'semantic-ui-react';
import _ from 'lodash';
import formatter from '../../../utils/formatter';
import { FadeInView } from '../../FadeInView';

const { Row, Column } = Grid;


class ConfirmPasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      secondValue: '',
      valid: false,
      message: `El campo ${props.label} es requerido`,
      dirty: false,
      firstErrorVisible: false,
      secondErrorVisible: false,
    };
  }


  // -----------------------------
  // ------ life cycle events ----
  // -----------------------------
  componentDidMount() {
    this.setForm();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !_.isEqual(nextState, this.state) || !_.isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.eq(prevState, this.state)) {
      this.setForm();
    }
  }


  // -----------------------
  // ------ user events ----
  // -----------------------
  onChange(e) {
    if (!e.target.value) {
      this.setState({
        value: '',
        valid: false,
        message: 'El campo de contraseña es requerido',
        firstErrorVisible: this.props.showErrors,
        secondErrorVisible: false,
      });
    } else if (!this.props.max || (this.props.max && e.target.value.length <= this.props.max)) {
      this.setState({ value: e.target.value });

      if (e.target.value && !this.state.secondValue) {
        this.setState({
          valid: false,
          message: 'Falta la confirmación de la contraseña',
          firstErrorVisible: false,
          secondErrorVisible: this.props.showErrors,
        });
      } else if (this.state.dirty && e.target.value !== this.state.secondValue) {
        this.setState({
          valid: false,
          message: 'Las contraseñas no coinciden',
          firstErrorVisible: false,
          secondErrorVisible: this.props.showErrors,
        });
      } else if (e.target.value === this.state.secondValue) {
        this.setState({
          valid: true,
          message: '',
          firstErrorVisible: false,
          secondErrorVisible: false,
        });
      }
    }
  }

  onBlur() {
    const { value } = this.state;

    if (!value) {
      this.setState({
        valid: false,
        message: `El campo ${this.props.label} es requerido`,
        firstErrorVisible: this.props.showErrors,
        secondErrorVisible: false,
      });
    } else if (this.props.min && value.length < this.props.min) {
      this.setState({
        valid: false,
        message: `El campo ${this.props.label} debe contener al menos ${this.props.min} caracteres`,
        firstErrorVisible: this.props.showErrors,
        secondErrorVisible: false,
      });
    }
  }

  onSecondChange(e) {
    this.setState({ secondValue: e.target.value, dirty: true });

    // validar inputs
    if (this.state.value !== '' && this.state.value === e.target.value) {
      this.setState({
        valid: true,
        message: '',
        firstErrorVisible: false,
        secondErrorVisible: false,
      });
    } else {
      this.setState({
        valid: false,
        message: 'Las contraseñas no coinciden',
        firstErrorVisible: false,
        secondErrorVisible: this.props.showErrors,
      });
    }
  }

  onSecondBlur() {
    const { secondValue } = this.state;

    if (!secondValue) {
      this.setState({
        valid: false,
        message: 'La confirmación de la contraseña es requerida',
        firstErrorVisible: false,
        secondErrorVisible: this.props.showErrors,
      });
    } else if (this.props.min && secondValue.length < this.props.min) {
      this.setState({
        valid: false,
        message: `La contraseña debe contener al menos ${this.props.min} caracteres`,
        firstErrorVisible: false,
        secondErrorVisible: this.props.showErrors,
      });
    }
  }

  setForm() {
    this.props.setFormData(this.props.name, this.state);
  }

  getValue() {
    return this.state.value;
  }

  dirtInput() {
    this.setState({ dirty: true });
  }

  resetInput() {
    this.setState({
      value: '',
      secondValue: '',
      valid: false,
      message: `El campo ${this.props.label} es requerido`,
      dirty: false,
      firstErrorVisible: false,
      secondErrorVisible: false,
    });
  }

  showError() {
    this.setState({ firstErrorVisible: true });
  }


  // --------------------------
  // ------ render methods ----
  // --------------------------
  renderFirstErrorLabel() {
    const { firstErrorVisible, message } = this.state;

    if (firstErrorVisible) {
      return (
        <FadeInView style={styles.popUpContainer}>
          <Label basic pointing color="red" style={styles.popUpErrorLabel}>
            { message }
          </Label>
        </FadeInView>
      );
    }

    return null;
  }

  renderSecondErrorLabel() {
    const { secondErrorVisible, message } = this.state;

    if (secondErrorVisible) {
      return (
        <FadeInView style={styles.popUpContainer}>
          <Label basic pointing color="red" style={styles.popUpErrorLabel}>
            { message }
          </Label>
        </FadeInView>
      );
    }

    return null;
  }

  renderInputs() {
    const invalidInput = this.state.dirty && !this.state.valid;
    let className = '';
    let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
    let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

    if (this.props.className) {
      className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
    } else {
      className = invalidInput ? 'invalid' : 'valid';
    }

    if (invalidInput) {
      finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
      finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
    }

    if (this.props.display === 'row' && !this.props.inlineLabel) {
      return [
        <Row key={1}>
          <Column textAlign="left">
            <label style={{ ...finalLabelStyle, fontWeight: 'bold' }}>
              { this.props.label }
            </label>

            <input
              type="password"
              value={this.state.value}
              name={this.props.name}
              className={className}
              onChange={this.onChange.bind(this)}
              onBlur={this.onBlur.bind(this)}
              placeholder={this.props.placeholder}
              style={{ ...finalInputStyle, marginTop: 5 }}
            />

            { this.renderFirstErrorLabel() }
          </Column>
        </Row>,
        <Row key={2}>
          <Column textAlign="left">
            <label style={{ ...finalLabelStyle, fontWeight: 'bold' }}>
              { this.props.secondLabel || 'Confirmar contraseña' }
            </label>

            <input
              type="password"
              value={this.state.secondValue}
              name={`confirmacion${this.props.name}`}
              className={className}
              onChange={this.onSecondChange.bind(this)}
              onBlur={this.onSecondBlur.bind(this)}
              placeholder={this.props.secondPlaceholder}
              style={{ ...finalInputStyle, marginTop: 5 }}
            />

            { this.renderSecondErrorLabel() }
          </Column>
        </Row>,
      ];
    }

    if (this.props.display === 'row' && this.props.inlineLabel) {
      return [
        <Row key={1}>
          <Column textAlign="left">
            <Input
              label={this.props.label}
              type="password"
              value={this.state.value}
              name={this.props.name}
              className={className}
              onChange={this.onChange.bind(this)}
              onBlur={this.onBlur.bind(this)}
              placeholder={this.props.placeholder}
              style={finalInputStyle}
            />

            { this.renderFirstErrorLabel() }
          </Column>
        </Row>,
        <Row key={2}>
          <Column textAlign="left">
            <Input
              label={this.props.secondLabel || 'Confirmar contraseña'}
              type="password"
              value={this.state.secondValue}
              name={`confirmacion${this.props.name}`}
              className={className}
              onChange={this.onSecondChange.bind(this)}
              onBlur={this.onSecondBlur.bind(this)}
              placeholder={this.props.secondPlaceholder}
              style={finalInputStyle}
            />

            { this.renderSecondErrorLabel() }
          </Column>
        </Row>,
      ];
    }

    if (this.props.display === 'column' && !this.props.inlineLabel) {
      return (
        <Row>
          <Column width={8} textAlign="left">
            <label style={{ ...finalLabelStyle, fontWeight: 'bold' }}>
              { this.props.label }
            </label>

            <input
              type="password"
              value={this.state.value}
              name={this.props.name}
              className={className}
              onChange={this.onChange.bind(this)}
              onBlur={this.onBlur.bind(this)}
              placeholder={this.props.placeholder}
              style={{ ...finalInputStyle, marginTop: 5 }}
            />

            { this.renderFirstErrorLabel() }
          </Column>
          <Column width={8}>
            <label style={{ ...finalLabelStyle, fontWeight: 'bold' }}>
              { this.props.secondLabel || 'Confirmar contraseña' }
            </label>

            <input
              type="password"
              value={this.state.secondValue}
              name={`confirmacion${this.props.name}`}
              className={className}
              onChange={this.onSecondChange.bind(this)}
              onBlur={this.onSecondBlur.bind(this)}
              placeholder={this.props.secondPlaceholder}
              style={{ ...finalInputStyle, marginTop: 5 }}
            />

            { this.renderSecondErrorLabel() }
          </Column>
        </Row>
      );
    }

    if (this.props.display === 'column' && this.props.inlineLabel) {
      return (
        <Row>
          <Column width={8} textAlign="left">
            <Input
              label={this.props.label}
              type="password"
              value={this.state.value}
              name={this.props.name}
              className={className}
              onChange={this.onChange.bind(this)}
              onBlur={this.onBlur.bind(this)}
              placeholder={this.props.placeholder}
              style={finalInputStyle}
            />

            { this.renderFirstErrorLabel() }
          </Column>
          <Column width={8}>
            <Input
              label={this.props.secondLabel || 'Confirmar contraseña'}
              type="password"
              value={this.state.secondValue}
              name={`confirmacion${this.props.name}`}
              className={className}
              onChange={this.onSecondChange.bind(this)}
              onBlur={this.onSecondBlur.bind(this)}
              placeholder={this.props.secondPlaceholder}
              style={finalInputStyle}
            />

            { this.renderSecondErrorLabel() }
          </Column>
        </Row>
      );
    }

    return null;
  }

  render() {
    return (
      <Form.Field>
        <Grid stackable>
          { this.renderInputs() }
        </Grid>
      </Form.Field>
    );
  }
}


// estilos
const styles = {
  label: {

  },
  errorLabel: {
    color: '#9F3A38',
  },
  input: {

  },
  errorInput: {
    background: 'rgba(224, 180, 180, 0.48)',
    border: '1px solid #9F3A38',
    color: '#9F3A38',
  },
  popUpContainer: {
    position: 'absolute',
    top: 55,
    left: 0,
    zIndex: 100,
  },
  popUpErrorLabel: {
    fontSize: 13,
    textAlign: 'center',
    boxShadow: 'rgba(100, 100, 100, 0.25) 0px 2px 4px',
  },
};


ConfirmPasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  secondLabel: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  secondPlaceholder: PropTypes.string.isRequired,
  display: PropTypes.oneOf(['row', 'column']).isRequired,
  inlineLabel: PropTypes.bool,
  showErrors: PropTypes.bool,
  className: PropTypes.string,
  labelStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};


// exportar componente
export default ConfirmPasswordInput;
