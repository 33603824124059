import axios from 'axios';
import { API_URL } from '../../../utils/constants';


export function resetDriverPassword(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/api/conductor/restore-password`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
}

export function resetUserPassword(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/api/usuario/restore-password`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
}

export function validateDriverEmail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/api/conductor/validate-email`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
}

export function validateUserEmail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/api/usuario/validate-email`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
}
