import { takeLatest, all } from 'redux-saga/effects';
import { RESET_PASSWORD, VALIDATE_EMAIL } from '../store/types';
import { resetPassword, validateEmail } from './workers';


export default function* watcherSaga() {
  yield all([
    takeLatest(RESET_PASSWORD, resetPassword),
    takeLatest(VALIDATE_EMAIL, validateEmail),
  ]);
}
