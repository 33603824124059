import * as actionsTypes from './types';


export const setHeaderVisibility = (params) => ({
  type: actionsTypes.SET_HEADER_VISIBILITY,
  payload: params,
});

export const setWhatsAppPopupVisibility = (params) => ({
  type: actionsTypes.SET_WHATSAPP_POPUP_VISIBILITY,
  payload: params,
});

export const resetPassword = (params) => ({
  type: actionsTypes.RESET_PASSWORD,
  payload: params,
});

export const validateEmail = (params) => ({
  type: actionsTypes.VALIDATE_EMAIL,
  payload: params,
});
