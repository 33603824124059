import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown, Label } from 'semantic-ui-react';
import _ from 'lodash';
import formatter from '../../../utils/formatter';
import { FadeInView } from '../../FadeInView';


class DropdownInput extends Component {
  constructor(props) {
    super(props);

    let valid;
    if (!props.multiple && props.defaultValue !== '' && props.defaultValue !== undefined && props.defaultValue !== null) {
      valid = true;
    } else if (props.multiple && props.defaultValue && props.defaultValue.length) {
      valid = true;
    } else {
      valid = !props.required;
    }

    let value = props.multiple ? [] : '';
    if (props.defaultValue !== '' && props.defaultValue !== undefined && props.defaultValue !== null) {
      value = props.defaultValue;
    }

    this.state = {
      value,
      valid,
      message: props.required ? `El campo ${props.label} es requerido` : '',
      dirty: false,
      errorVisible: false,
    };
  }


  // -----------------------------
  // ------ life cycle events ----
  // -----------------------------
  componentDidMount() {
    this.setForm();
  }

  componentWillReceiveProps(nextProps) {
    const defaultForSingleOption = !nextProps.multiple && nextProps.defaultValue !== '' && nextProps.defaultValue !== undefined && nextProps.defaultValue !== null;
    const defaultForMultipleOptions = nextProps.multiple && nextProps.defaultValue && nextProps.defaultValue.length;

    if (!this.state.dirty && (defaultForSingleOption || defaultForMultipleOptions)) {
      this.setState({ value: nextProps.defaultValue, valid: true });
    }

    if (!_.isEqual(this.props.options, nextProps.options)) {
      const initialValue = this.props.multiple ? [] : '';
      this.setState({ value: initialValue });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !_.isEqual(nextState, this.state) || !_.isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    const valueForSingleOption = !this.props.multiple && this.state.value !== '' && this.state.value !== undefined && this.state.value !== null;
    const previousValueForSingleOption = !prevProps.multiple && prevState.value !== '' && prevState.value !== undefined && prevState.value !== null;
    const valueForMultipleOptions = this.props.multiple && this.state.value && this.state.value.length;
    const previousValueForMultipleOptions = prevProps.multiple && prevState.value && prevState.value.length;
    const currentValue = valueForSingleOption || valueForMultipleOptions;
    const previousValue = previousValueForSingleOption || previousValueForMultipleOptions;

    if (!_.eq(prevState, this.state)) {
      this.setForm();
    }

    if (previousValue && !prevProps.options.length && !currentValue && this.props.options.length) {
      this.setValue(prevState.value);
    }
  }


  // -----------------------
  // ------ user events ----
  // -----------------------
  onChange(e, data) {
    // actualizar valor
    this.setState({ value: data.value, dirty: true });
    const invalidValue = data.value === '' || data.value === undefined || data.value === null || data.value.length === 0;

    // validar input
    if (this.props.required && invalidValue) {
      this.setState({
        valid: false,
        message: `El campo ${this.props.label} es requerido`,
        errorVisible: this.props.showErrors,
      });
    } else {
      this.setState({
        valid: true,
        message: '',
        errorVisible: false,
      });
    }
  }

  setForm() {
    this.props.setFormData(this.props.name, this.state);
  }

  setValue(value) {
    this.setState({ value });
  }

  getValue() {
    return this.state.value;
  }

  dirtInput() {
    this.setState({ dirty: true });
  }

  resetInput() {
    let valid;
    if (!this.props.multiple && this.props.defaultValue !== '' && this.props.defaultValue !== undefined && this.props.defaultValue !== null) {
      valid = true;
    } else if (this.props.multiple && this.props.defaultValue && this.props.defaultValue.length) {
      valid = true;
    } else {
      valid = !this.props.required;
    }

    let value = this.props.multiple ? [] : '';
    if (this.props.defaultValue !== '' && this.props.defaultValue !== undefined && this.props.defaultValue !== null) {
      value = this.props.defaultValue;
    }

    this.setState({
      value,
      valid,
      message: this.props.required ? `El campo ${this.props.label} es requerido` : '',
      dirty: false,
      errorVisible: false,
    });
  }

  showError() {
    this.setState({ errorVisible: true });
  }


  // --------------------------
  // ------ render methods ----
  // --------------------------
  renderErrorLabel() {
    const { errorVisible, message } = this.state;

    if (errorVisible) {
      return (
        <FadeInView style={styles.popUpContainer}>
          <Label basic pointing color="red" style={styles.popUpErrorLabel}>
            { message }
          </Label>
        </FadeInView>
      );
    }

    return null;
  }

  render() {
    const invalidInput = this.state.dirty && !this.state.valid;
    let className = '';
    let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
    let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

    if (this.props.className) {
      className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
    } else {
      className = invalidInput ? 'invalid' : 'valid';
    }

    if (invalidInput) {
      finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
      finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
    }

    return (
      <Form.Field>
        <label style={finalLabelStyle}>
          { this.props.label }
        </label>

        <Dropdown
          fluid
          selection
          name={this.props.name}
          className={className}
          value={this.state.value}
          onChange={this.onChange.bind(this)}
          onOpen={() => { this.setState({ errorVisible: false }); }}
          disabled={this.props.readOnly}
          placeholder={this.props.placeholder}
          multiple={this.props.multiple}
          search={this.props.search}
          options={this.props.options}
          loading={this.props.loading}
          style={finalInputStyle}
          noResultsMessage={this.props.noResultsMessage || 'No se encontraron resultados'}
          onSearchChange={this.props.onSearchChange}
        />

        { this.renderErrorLabel() }
      </Form.Field>
    );
  }
}


// estilos
const styles = {
  label: {

  },
  errorLabel: {
    color: '#9F3A38',
  },
  input: {

  },
  errorInput: {
    background: 'rgba(224, 180, 180, 0.48)',
    border: '1px solid #9F3A38',
    color: '#9F3A38',
  },
  popUpContainer: {
    position: 'absolute',
    top: 75,
    left: 0,
    zIndex: 100,
  },
  popUpErrorLabel: {
    fontSize: 13,
    textAlign: 'center',
    boxShadow: 'rgba(100, 100, 100, 0.25) 0px 2px 4px',
  },
};


DropdownInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  search: PropTypes.bool,
  showErrors: PropTypes.bool,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  labelStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  required: PropTypes.bool,
  noResultsMessage: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  setFormData: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func,
};


// exportar componente
export default DropdownInput;
