export const WHITE = '#FFFFFF';
export const DARK_BLACK = 'rgb(6, 6, 5)';
export const BLACK = 'rgb(34,34,34)';
export const LIGHT_BLACK = 'rgb(62, 63, 65)';
export const TABLE_HEADER = '#757575';
export const GRAY = '#717885';
export const LIGHT_GRAY = '#C5C5C5';
export const BACKGROUND_GRAY = 'rgba(217, 209, 201, 0.3)';
export const SAND = '#F3F0EF';
export const GREEN = '#029900';
export const LIGHT_GREEN = '#2cbcb3';
export const METAL = '#6B8596';
export const YELLOW = '#fdbd39';
export const SHADOW = 'rgba(100, 100, 100, 0.25) 0px 2px 4px';
export const RED = '#e50202';
export const BLUE = '#4286f4';
export const TAXONE_YELLOW = '#FCE301';
export const ORANGE = '#F6783B';
