import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Label } from 'semantic-ui-react';
import _ from 'lodash';
import formatter from '../../../utils/formatter';
import { FadeInView } from '../../FadeInView';

const ASCENDING = 'ascending';
const DESCENDING = 'descending';
const { Column } = Grid;


class NumericInput extends Component {
  constructor(props) {
    super(props);
    const existDefaultValue = props.defaultValue !== undefined && props.defaultValue !== null && props.defaultValue !== '';

    let valid;
    if (existDefaultValue) {
      valid = true;
    } else {
      valid = !props.required;
    }

    let value = '';
    if (existDefaultValue && props.mask) {
      value = this.applyMask(props.defaultValue);
    } else if (existDefaultValue && !props.currency) {
      value = props.defaultValue;
    } else if (existDefaultValue && props.currency) {
      value = this.currencyFormat(props.defaultValue);
    }

    this.state = {
      value,
      valid,
      message: props.required ? `El campo ${props.label} es requerido` : '',
      dirty: false,
      errorVisible: false,
    };
  }


  // -----------------------------
  // ------ life cycle events ----
  // -----------------------------
  componentDidMount() {
    this.setForm();
  }

  componentWillReceiveProps(nextProps) {
    const existDefaultValue = nextProps.defaultValue !== undefined && nextProps.defaultValue !== null && nextProps.defaultValue !== '';

    if (!this.state.dirty && existDefaultValue) {
      let value = nextProps.defaultValue;

      if (nextProps.mask) {
        value = this.applyMask(nextProps.defaultValue);
      } else if (nextProps.currency) {
        const parsedNumber = parseFloat(value);
        value = this.currencyFormat(`${parsedNumber}`);
      }

      this.setState({ value, valid: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !_.isEqual(nextState, this.state) || !_.isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.eq(prevState, this.state)) {
      this.setForm();
    }
  }


  // -----------------------
  // ------ user events ----
  // -----------------------
  onChange(e) {
    if (this.props.mask) {
      this.maskedOnChange(e);
    } else {
      this.normalOnChange(e);
    }
  }

  onBlur(e) {
    if (this.props.mask) {
      this.maskedOnBlur(e);
    } else {
      this.normalOnBlur(e);
    }
  }

  getNumericValue(rawValue) {
    let numericValue;
    let validNumber = true;

    // get numeric value
    if (this.props.currency) {
      numericValue = rawValue.replace(/[$]/g, '');
      numericValue = numericValue.replace(/[,]/g, '');
    } else {
      numericValue = rawValue;
    }

    // valid number
    if (numericValue === '0') {
      validNumber = true;
    } else if (numericValue && !parseFloat(numericValue)) {
      validNumber = false;
    }

    if (validNumber) {
      if (this.props.currency) {
        const formattedNumber = this.currencyFormat(numericValue);

        if (this.props.required && !numericValue.length) {
          validNumber = false;
        }

        return {
          numericValue,
          value: formattedNumber,
          length: numericValue.length,
          valid: validNumber,
        };
      }

      return {
        numericValue,
        value: numericValue,
        length: rawValue.length,
        valid: true,
      };
    }

    return {
      numericValue,
      value: rawValue,
      length: rawValue.length,
      valid: validNumber,
    };
  }

  setForm() {
    this.props.setFormData(this.props.name, this.state);
  }

  getValue() {
    let numericValue = '';

    if (this.props.mask) {
      const valueArray = this.state.value.toString().split('');
      const placeholdersPositions = this.getPlaceholdersPositions();

      placeholdersPositions.forEach((placeholderPosition) => {
        numericValue += valueArray[placeholderPosition];
      });
    } else if (this.props.currency) {
      numericValue = this.state.value.toString().replace(/[$]/g, '');
      numericValue = numericValue.replace(/[,]/g, '');
    } else {
      numericValue = this.state.value;
    }

    if (numericValue === 0) {
      return 0;
    }

    return numericValue ? parseFloat(numericValue) : '';
  }

  getPlaceholdersPositions() {
    const { mask } = this.props;
    const maskArray = mask.split('');
    const placeholdersPositions = [];

    maskArray.forEach((character, index) => {
      if (character === 'x') {
        placeholdersPositions.push(index);
      }
    });

    return placeholdersPositions;
  }

  normalOnChange(e) {
    let regex;

    if (this.props.integer) {
      regex = /[^\d]/g;
    } else if (this.props.currency) {
      regex = /[^.,$\d]/g;
    } else {
      regex = /[^.\d]/g;
    }

    if (this.props.required && !e.target.value) {
      this.setState({
        valid: false,
        dirty: true,
        value: '',
        message: `El campo ${this.props.label} es requerido`,
      });
    } else if (!regex.test(e.target.value)) {
      this.setState({ errorVisible: false });
      const number = this.getNumericValue(e.target.value);

      if (number.valid) {
        const newValue = {
          value: number.value,
          message: '',
          valid: true,
          dirty: true,
        };

        if (this.props.max && number.length <= this.props.max) {
          this.setState(newValue);
        } else if (!this.props.max) {
          this.setState(newValue);
        }
      } else {
        this.setState({
          value: number.value,
          valid: false,
          message: `El campo ${this.props.label} no tiene un formato valido`,
          dirty: true,
        });
      }
    }
  }

  normalOnBlur(e) {
    const number = this.getNumericValue(e.target.value);

    if (this.props.required && !e.target.value) {
      this.setState({
        valid: false,
        value: '',
        message: `El campo ${this.props.label} es requerido`,
        errorVisible: this.props.showErrors,
      });
    } else if (number.numericValue && number.numericValue !== '0' && !parseFloat(number.numericValue)) {
      this.setState({
        valid: false,
        message: `El campo ${this.props.label} no tiene un formato valido`,
        errorVisible: this.props.showErrors,
      });
    } else if (parseFloat(number.numericValue)) {
      const parsedNumber = parseFloat(number.numericValue);

      if (this.props.min && number.length >= this.props.min) {
        this.setState({
          value: this.props.currency ? this.currencyFormat(`${parsedNumber}`) : parsedNumber,
          valid: true,
          message: '',
        });
      } else if (this.props.min && number.length < this.props.min) {
        this.setState({
          value: this.props.currency ? this.currencyFormat(`${parsedNumber}`) : parsedNumber,
          valid: false,
          message: `El campo ${this.props.label} debe contener al menos ${this.props.min} numeros`,
          errorVisible: this.props.showErrors,
        });
      } else if (!this.props.min) {
        this.setState({
          value: this.props.currency ? this.currencyFormat(`${parsedNumber}`) : parsedNumber,
          valid: true,
          message: '',
        });
      }
    }
  }

  maskedOnChange(e) {
    const { value } = this.state;
    const { mask } = this.props;
    const regex = /[^\d]/g;
    const incomingValueArray = e.target.value.split('');
    const currentValueArray = value.split('');
    const direction = incomingValueArray.length > currentValueArray.length ? ASCENDING : DESCENDING;
    const lastCharacterIndex = incomingValueArray.length - 1;
    const lastCharacter = incomingValueArray[lastCharacterIndex];
    const isPlaceholder = this.isPlaceholder(lastCharacterIndex);
    const isNumeric = !regex.test(lastCharacter);
    const isFirstCharacter = incomingValueArray.length === 1;
    let validCharacter = true;

    if (isPlaceholder && !isNumeric) {
      validCharacter = false;
    } else if (!isPlaceholder && isFirstCharacter && !isNumeric) {
      validCharacter = false;
    }

    if (direction === DESCENDING) {
      validCharacter = true;
    }

    if (validCharacter) {
      const maskArray = mask.split('');
      const newValueArray = [];
      const placeholdersPositions = this.getPlaceholdersPositions();
      let charactersPlaced = 0;

      if (direction === ASCENDING) {
        const rawValueArray = maskArray.map((character, index) => {
          if (character === 'x') {
            let characterToPlace;

            if (!charactersPlaced && incomingValueArray.length === 1) {
              charactersPlaced += 1;
              characterToPlace = incomingValueArray[0];
            } else if (incomingValueArray[index]) {
              charactersPlaced += 1;
              characterToPlace = incomingValueArray[index];

              if (regex.test(characterToPlace)) {
                validCharacter = false;
              }
            }

            return characterToPlace;
          } else if (maskArray[index] !== incomingValueArray[index]) {
            incomingValueArray.splice(index, 0, maskArray[index]);
          }

          return character;
        });

        if (charactersPlaced === placeholdersPositions.length) {
          rawValueArray.forEach((character) => { newValueArray.push(character); });
        } else {
          const lastIndex = placeholdersPositions[charactersPlaced];

          rawValueArray.forEach((character, index) => {
            if (index < lastIndex) {
              newValueArray.push(character);
            }
          });
        }
      } else {
        while (incomingValueArray.length) {
          const lastIndex = incomingValueArray.length - 1;

          if (this.isPlaceholder(lastIndex)) {
            break;
          } else {
            incomingValueArray.pop();
          }
        }

        incomingValueArray.forEach((character) => {
          newValueArray.push(character);
        });
      }

      const finalValue = newValueArray.reduce((sum, currentItem) => (
        sum + currentItem
      ), '');

      if (validCharacter) {
        this.setState({
          value: finalValue,
          valid: true,
          dirty: true,
          errorVisible: false,
        });
      }
    }
  }

  maskedOnBlur() {
    const { value } = this.state;
    const placeholdersPositions = this.getPlaceholdersPositions();
    const valueArray = value.split('');
    const regex = /[^\d]/g;
    const newState = { valid: true, message: '', dirty: true };

    for (let index = 0; index < placeholdersPositions.length; index += 1) {
      const placeholderIndex = placeholdersPositions[index];
      const currentCharacter = valueArray[placeholderIndex];

      if (!currentCharacter) {
        newState.valid = false;
        newState.message = `El campo ${this.props.label} debe contener ${placeholdersPositions.length} numeros`;

        if (this.props.showErrors) {
          newState.errorVisible = true;
        }

        break;
      }

      if (regex.test(currentCharacter)) {
        newState.valid = false;
        newState.message = `El campo ${this.props.label} contiene caracteres no validos`;

        if (this.props.showErrors) {
          newState.errorVisible = true;
        }

        break;
      }
    }

    this.setState(newState);
  }

  isPlaceholder(index) {
    const placeholdersPositions = this.getPlaceholdersPositions();

    const foundIndex = placeholdersPositions.filter((placeholdersPosition) =>
      placeholdersPosition === index
    );

    return foundIndex.length > 0;
  }

  currencyFormat(value) {
    const arrayNumber = value.toString().split('.');
    const formattedNumber = `$${arrayNumber[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;

    if (arrayNumber.length > 1) {
      return `${formattedNumber}.${arrayNumber[1]}`;
    }

    return formattedNumber;
  }

  applyMask(numericValue) {
    const maskArray = this.props.mask.split('');
    const numericValueArray = numericValue.toString().split('');
    let maskedValue = '';
    let nextIndex = 0;

    maskArray.forEach((character) => {
      if (character === 'x') {
        maskedValue += numericValueArray[nextIndex];
        nextIndex += 1;
      } else {
        maskedValue += character;
      }
    });

    return maskedValue;
  }

  dirtInput() {
    this.setState({ dirty: true });
  }

  resetInput() {
    const existDefaultValue = this.props.defaultValue !== undefined && this.props.defaultValue !== null;

    let valid;
    if (existDefaultValue) {
      valid = true;
    } else {
      valid = !this.props.required;
    }

    let value = '';
    if (existDefaultValue && this.props.mask) {
      value = this.applyMask(this.props.defaultValue);
    } else if (existDefaultValue && !this.props.currency) {
      value = this.props.defaultValue;
    } else if (existDefaultValue && this.props.currency) {
      value = this.currencyFormat(this.props.defaultValue);
    }

    this.setState({
      value,
      valid,
      message: this.props.required ? `El campo ${this.props.label} es requerido` : '',
      dirty: false,
      errorVisible: false,
    });
  }

  showError() {
    this.setState({ errorVisible: true });
  }


  // --------------------------
  // ------ render methods ----
  // --------------------------
  renderErrorLabel() {
    const { errorVisible, message } = this.state;

    if (errorVisible) {
      return (
        <FadeInView style={styles.popUpContainer}>
          <Label basic pointing color="red" style={styles.popUpErrorLabel}>
            { message }
          </Label>
        </FadeInView>
      );
    }

    return null;
  }

  render() {
    const invalidInput = this.state.dirty && !this.state.valid;
    let className = '';
    let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
    let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

    if (this.props.className) {
      className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
    } else {
      className = invalidInput ? 'invalid' : 'valid';
    }

    if (invalidInput) {
      finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
      finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
    }

    // render input
    if (this.props.inlineLabel) {
      return (
        <Form.Field onBlur={this.onBlur.bind(this)}>
          <Grid>
            <Column width={3} textAlign="right" verticalAlign="middle">
              <label style={{ ...finalLabelStyle, fontWeight: 'bold' }}>
                { this.props.label }:
              </label>
            </Column>

            <Column width={13}>
              <input
                value={this.state.value}
                name={this.props.name}
                className={className}
                onChange={this.onChange.bind(this)}
                disabled={this.props.readOnly}
                placeholder={this.props.placeholder}
                style={finalInputStyle}
              />

              { this.renderErrorLabel() }
            </Column>
          </Grid>
        </Form.Field>
      );
    }

    return (
      <Form.Field onBlur={this.onBlur.bind(this)}>
        <label style={finalLabelStyle}>
          { this.props.label }
        </label>

        <input
          value={this.state.value}
          name={this.props.name}
          className={className}
          onChange={this.onChange.bind(this)}
          disabled={this.props.readOnly}
          placeholder={this.props.placeholder}
          style={finalInputStyle}
        />

        { this.renderErrorLabel() }
      </Form.Field>
    );
  }
}


// estilos
const styles = {
  label: {

  },
  errorLabel: {
    color: '#9F3A38',
  },
  input: {

  },
  errorInput: {
    background: 'rgba(224, 180, 180, 0.48)',
    border: '1px solid #9F3A38',
    color: '#9F3A38',
  },
  popUpContainer: {
    position: 'absolute',
    top: 75,
    left: 0,
    zIndex: 100,
  },
  popUpErrorLabel: {
    fontSize: 13,
    textAlign: 'center',
    boxShadow: 'rgba(100, 100, 100, 0.25) 0px 2px 4px',
  },
};


NumericInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inlineLabel: PropTypes.bool,
  inputStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  className: PropTypes.string,
  mask: PropTypes.string,
  required: PropTypes.bool,
  showErrors: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.bool,
  setFormData: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  integer: PropTypes.bool,
};


// exportar componente
export default NumericInput;
