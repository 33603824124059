import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Image } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import formatter from '../../../../utils/formatter';
import eventManager from '../../../../utils/eventManager';
import logoImage from '../../../../assets/logo.png';
import logoText from '../../../../assets/logo-text.png';
import { ORANGE } from '../../../../colors';
import { setHeaderVisibility } from '../../store/actions';

const { Row, Column } = Grid;

const styles = {
  headerContainer: {
    background: 'white',
    boxShadow: '0 3px 3px rgb(0,0,0,0.2)',
    zIndex: 200,
    position: 'fixed',
    top: 0,
    left: 0,
    margin: 0,
    width: '100%'
  },
  menuButton: {
    color: 'black',
    fontWeight: 'bold',
    padding: 10,
    borderRadius: 3,
    transition: 'all 300ms ease 0s',
    fontFamily: 'avenir, Open-Sans, Lato, Arial',
  },
  hoveredMenuButton: {
    color: 'white',
    background: ORANGE,
  },
};


class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverOption: null,
      mobileMenuVisible: false,
      mainContainerScrollPosition: 0,
    };
  }

  componentDidMount() {
    // this.initScrollEvents();

    this.navigateCallbackID = eventManager.on('navigate', () => {
      this.props.setHeaderVisibility(true);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.mainContainerScrollPosition < this.state.mainContainerScrollPosition) {
      this.props.setHeaderVisibility(false);
      this.setState({ mobileMenuVisible: false });
    }

    if (prevState.mainContainerScrollPosition > this.state.mainContainerScrollPosition) {
      this.props.setHeaderVisibility(true);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
    eventManager.unsubscribe('navigate', this.navigateCallbackID);
  }


  // -------------------
  // ----- methods -----
  // --------------------
  onOptionClick(option) {
    if (option.url !== undefined) {
      this.props.navigate(option.url);
    }
  }

  onMainLogoClick() {
    this.props.navigate('/');
  }

  onSidebarButtonClick() {
    const { mobileMenuVisible } = this.state;
    this.setState({ mobileMenuVisible: !mobileMenuVisible });
  }

  getHeaderClass() {
    const { headerVisible } = this.props;
    return headerVisible ? 'visible-header' : 'hidden-header';
  }

  initScrollEvents() {
    // if (window.innerWidth < MOBILE_BREAKPOINT) {
      window.onscroll = () => {
        const scrollPosition = window.pageYOffset;
        this.setState({ mainContainerScrollPosition: scrollPosition });
      };
    // }
  }


  // --------------------------
  // ----- render methods -----
  // --------------------------
  renderDesktopHeader() {
    return (
      <Row columns={2} only="tablet computer">
        <Column style={{ height: 60, display: 'flex', alignItems: 'center' }}>
          <Image
            src={logoImage}
            style={{ width: 54, marginTop: 5, marginBottom: 5, cursor: 'pointer' }}
          />

          <Image
            src={logoText}
            style={{ width: 154, marginTop: 5, marginBottom: 5, cursor: 'pointer' }}
          />
        </Column>
      </Row>
    );
  }

  renderMobileHeader() {
    return (
      <Row only="mobile" style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Column>
          <Grid columns={2}>
            <Column style={{ height: 88, display: 'flex', alignItems: 'center' }}>
              <Image
                src={logoImage}
                style={{ width: 40, marginTop: 5, marginBottom: 5, cursor: 'pointer' }}
              />

              <Image
                src={logoText}
                style={{ width: 154, marginTop: 5, marginBottom: 5, cursor: 'pointer' }}
              />
            </Column>
          </Grid>
        </Column>
      </Row>
    );
  }

  render() {
    return (
      <Grid id="main-header" className={this.getHeaderClass()} style={styles.headerContainer}>
        <Column style={{ padding: 0 }}>
          <Grid container>
            { this.renderMobileHeader() }
            { this.renderDesktopHeader() }
          </Grid>
        </Column>
      </Grid>
    );
  }
}


Header.propTypes = {
  navigate: PropTypes.func,
  headerVisible: PropTypes.bool,
  setHeaderVisibility: PropTypes.func,
};


const mapStateToProps = (state) => ({
  headerVisible: state.web.headerVisible,
});


const actions = {
  setHeaderVisibility,
};


export default connect(mapStateToProps, actions)(Header);
