import React, { Component } from 'react';
import { Provider } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import configureStore from '../configureStore';
import eventManager from '../utils/eventManager';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import WebLayout from '../containers/web/layout';
import { SHOW_NOTIFICATION } from '../utils/constants';

const store = configureStore();


class App extends Component {
  componentDidMount() {
    this.callbackID = eventManager.on(SHOW_NOTIFICATION, (data) => {
      if (this.notificationSystem) {
        this.notificationSystem.addNotification({
          level: data.type,
          message: data.message,
        });
      }
    });
  }

  componentWillUnmount() {
    eventManager.unsubscribe(SHOW_NOTIFICATION, this.callbackID);
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" component={WebLayout} />
          </Switch>

          <NotificationSystem ref={(ref) => { this.notificationSystem = ref; }} />
        </Router>
      </Provider>
    );
  }
}


export default App;
